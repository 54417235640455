import APLogo from "static/images/states/AP.png";
import ARLogo from "static/images/states/AR.png";
import ASLogo from "static/images/states/AS.png";
import BRLogo from "static/images/states/BR.png";
import CGLogo from "static/images/states/CG.png";
import GALogo from "static/images/states/GA.png";
import GJLogo from "static/images/states/GJ.png";
import HRLogo from "static/images/states/HR.png";
import HPLogo from "static/images/states/HP.png";
import JKLogo from "static/images/states/JK.png";
import JHLogo from "static/images/states/JH.png";
import KALogo from "static/images/states/KA.png";
import KLLogo from "static/images/states/KL.png";
import MPLogo from "static/images/states/MP.png";
import MHLogo from "static/images/states/MH.png";
import MNLogo from "static/images/states/MN.png";
import MLLogo from "static/images/states/ML.png";
import MZLogo from "static/images/states/MZ.png";
import NLLogo from "static/images/states/NL.png";
import ORLogo from "static/images/states/OR.png";
import PBLogo from "static/images/states/PB.png";
import RJLogo from "static/images/states/RJ.png";
import SKLogo from "static/images/states/SK.png";
import TNLogo from "static/images/states/TN.png";
import TSLogo from "static/images/states/TS.png";
import TRLogo from "static/images/states/TR.png";
import UPLogo from "static/images/states/UP.png";
import UKLogo from "static/images/states/UK.png";
import WBLogo from "static/images/states/WB.png";
import NALogo from "static/images/states/NA.png";
import DLLogo from "static/images/states/DL.png";

export const STATE_GSTIN_ICON: any = {
  "37": APLogo, // Andhra Pradesh
  "12": ARLogo, // Arunachal Pradesh
  "18": ASLogo, // Assam
  "10": BRLogo, // Bihar
  "22": CGLogo, // Chhattisgarh
  "30": GALogo, // Goa
  "24": GJLogo, // Gujarat
  "06": HRLogo, // Haryana
  "07": DLLogo,
  "02": HPLogo, // Himachal Pradesh
  "01": JKLogo, // Jammu & Kashmir
  "20": JHLogo, // Jharkhand
  "29": KALogo, // Karnataka
  "32": KLLogo, // Kerala
  "23": MPLogo, // Madhya Pradesh
  "27": MHLogo, // Maharashtra
  "14": MNLogo, // Manipur
  "17": MLLogo, // Meghalaya
  "15": MZLogo, // Mizoram
  "13": NLLogo, // Nagaland
  "21": ORLogo, // Odisha
  "03": PBLogo, // Punjab
  "08": RJLogo, // Rajasthan
  "11": SKLogo, // Sikkim
  "33": TNLogo, // Tamil Nadu
  "36": TSLogo, // Telangana
  "16": TRLogo, // Tripura
  "09": UPLogo, // Uttar Pradesh
  "05": UKLogo, // Uttarakhand
  "19": WBLogo, // West Bengal
  NA: NALogo,
};
