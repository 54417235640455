import PageHeader from "app/shared/PageHeader";
import "../../FinkAnalytics.scss";
import { Button, Checkbox, Input, Modal, Select, Switch, Tag } from "antd";
import SearchTable from "../SearchTable";
import { useState } from "react";
import { AgTableClient } from "app/shared/AgTable";

const dataTypeColor: any = {
  STRING: "#2980b9",
  NUMBER: "#27ae60",
  DATE: "#34495e",
};
export default function FASetup(props: any) {
  const [showModal, setShowModal] = useState(false);
  const columnDefs = [
    {
      field: "sourceKey",
      headerName: "Source Key",
      width: 150,
      suppressMenu: true,
    },
    {
      field: "destinationKey",
      headerName: "Destination Key",
      width: 150,
      suppressMenu: true,
    },
    {
      field: "dataType",
      headerName: "Data Type",
      suppressMenu: true,
      width: 120,
      cellRenderer: (params: any) => (
        <Tag
          color={dataTypeColor[params?.data?.dataType]}
          style={{ fontSize: 11 }}
        >
          {params?.data?.dataType}
        </Tag>
      ),
    },
    {
      field: "destinationKey",
      headerName: "Header Name",
      suppressMenu: true,
      width: 180,
      cellRenderer: (params: any) => (
        <Input placeholder="Header Name" size="small" />
      ),
    },
    {
      field: "formatType",
      headerName: "UI Format Type",
      suppressMenu: true,
      width: 150,
      cellRenderer: (params: any) => (
        <Select
          defaultValue="lucy"
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "jack", label: "Jack" },
            { value: "lucy", label: "Lucy" },
            { value: "Yiminghe", label: "yiminghe" },
            { value: "disabled", label: "Disabled", disabled: true },
          ]}
        />
      ),
    },
    {
      field: "destinationKey",
      headerName: "Column Groping",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => <Switch size="small" />,
    },
    {
      field: "destinationKey",
      headerName: "Row Groping",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => <Switch defaultChecked size="small" />,
    },
    {
      field: "destinationKey",
      headerName: "Filter Type",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Select
          defaultValue="lucy"
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "jack", label: "Jack" },
            { value: "lucy", label: "Lucy" },
            { value: "Yiminghe", label: "yiminghe" },
            { value: "disabled", label: "Disabled", disabled: true },
          ]}
        />
      ),
    },
    {
      field: "destinationKey",
      headerName: "Aggregation",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => <Switch defaultChecked size="small" />,
    },
    {
      field: "destinationKey",
      headerName: "Column Pivot",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => <Switch defaultChecked size="small" />,
    },
    {
      field: "destinationKey",
      headerName: "Hide Column",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => <Switch defaultChecked size="small" />,
    },
    {
      field: "destinationKey",
      headerName: "Default Grouping",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => <Switch defaultChecked size="small" />,
    },
    {
      field: "destinationKey",
      headerName: "Default Sorting",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Select
          defaultValue="lucy"
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "jack", label: "Jack" },
            { value: "lucy", label: "Lucy" },
            { value: "Yiminghe", label: "yiminghe" },
            { value: "disabled", label: "Disabled", disabled: true },
          ]}
        />
      ),
    },
    {
      field: "destinationKey",
      headerName: "Default Pinning",
      suppressMenu: true,
      width: 140,
      cellRenderer: (params: any) => (
        <Select
          defaultValue="lucy"
          style={{ width: "100%" }}
          size="small"
          options={[
            { value: "jack", label: "Jack" },
            { value: "lucy", label: "Lucy" },
            { value: "Yiminghe", label: "yiminghe" },
            { value: "disabled", label: "Disabled", disabled: true },
          ]}
        />
      ),
    },
  ];
  const rowData = [
    { sourceKey: "orgname", destinationKey: "orgname", dataType: "STRING" },
    {
      sourceKey: "expenseclientid",
      destinationKey: "expenseclientid",
      dataType: "STRING",
    },

    {
      sourceKey: "workspace_a",
      destinationKey: "workspace_a",
      dataType: "STRING",
    },
    { sourceKey: "bookingId", destinationKey: "bookingId", dataType: "STRING" },
    {
      sourceKey: "bookingtype",
      destinationKey: "bookingtype",
      dataType: "STRING",
    },
    {
      sourceKey: "BookingID1",
      destinationKey: "BookingID1",
      dataType: "STRING",
    },
    {
      sourceKey: "Ticket/PNRNo",
      destinationKey: "Ticket/PNRNo",
      dataType: "STRING",
    },
    {
      sourceKey: "CreatedDate",
      destinationKey: "CreatedDate",
      dataType: "DATE",
    },
    {
      sourceKey: "UpdatedDate",
      destinationKey: "UpdatedDate",
      dataType: "DATE",
    },
    {
      sourceKey: "CheckinDate",
      destinationKey: "CheckinDate",
      dataType: "DATE",
    },
    {
      sourceKey: "CheckoutDate",
      destinationKey: "CheckoutDate",
      dataType: "DATE",
    },
    {
      sourceKey: "BookerEmail",
      destinationKey: "BookerEmail",
      dataType: "STRING",
    },
    {
      sourceKey: "totalAmount",
      destinationKey: "totalAmount",
      dataType: "NUMBER",
    },
    {
      sourceKey: "BookerEmployeeID",
      destinationKey: "BookerEmployeeID",
      dataType: "STRING",
    },
    {
      sourceKey: "BookerDepartment",
      destinationKey: "BookerDepartment",
      dataType: "STRING",
    },
    {
      sourceKey: "BookerComment",
      destinationKey: "BookerComment",
      dataType: "STRING",
    },
    {
      sourceKey: "NoofGuests",
      destinationKey: "NoofGuests",
      dataType: "NUMBER",
    },
  ];

  return (
    <div className="FASetup">
      <div className="Header">
        <PageHeader title="Fink Analytics Setup" />
      </div>
      <div className="SearchBoxContainer">
        <SearchTable
          placeholder="Search ZA Table"
          style={{ width: 400, height: 40 }}
        />
      </div>
      <Modal
        open={true}
        onCancel={() => setShowModal(false)}
        footer={false}
        title="Setup"
        width={1600}
      >
        <div style={{ height: 400, width: "100%" }}>
          <AgTableClient
            // @ts-ignore
            rowData={rowData}
            columnDefs={columnDefs}
            hideToolbar
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 24,
          }}
        >
          <div>
            <Checkbox>Create Table</Checkbox>
            <Checkbox style={{ marginLeft: 24 }}>Enable Auto Sync</Checkbox>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button style={{ marginRight: 12 }}>Cancel</Button>
            <Button type="primary">Save Configuration</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
