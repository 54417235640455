import FinkAnalytics from "./FinkAnalytics";
import FASetup from "./components/FASetup";

//eslint-disable-next-line
export default {
  path: "/FA/",
  element: <FinkAnalytics />,
  strict: true,
  children: [
    {
      path: "/FA/setup",
      element: <FASetup />,
      exact: true,
    },
  ],
};
