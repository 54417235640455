import React, { useState } from "react";
import { Select } from "antd";
import type { SelectProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (
  value: string,
  callback: (data: { value: string; text: string }[]) => void
) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const getTableList = () => {};
  if (value) {
    timeout = setTimeout(getTableList, 300);
  } else {
    callback([]);
  }
};

const SearchTable = (props: any) => {
  const [data, setData] = useState<SelectProps["options"]>([]);
  const [value, setValue] = useState<string>();

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      suffixIcon={<SearchOutlined style={{ fontSize: 20 }} />}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};

export default SearchTable;
