import PageHeader from "app/shared/PageHeader";
import "./Integrations.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import UnReconciledFlight from "../AppTour/components/OnboardUnReconcilied/UnReconciledFlight";
import UnReconciledHotel from "../AppTour/components/OnboardUnReconcilied/UnReconciledHotel";
import { Button, Modal, Tabs, Tag } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { PlusOutlined, LinkOutlined } from "@ant-design/icons";
import GSTIntegration from "./components/GSTIntegrations";
import AllIntegrations from "./components/AllIntegrations";
import {
  API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST,
  API_ENDPOINT_FETCH_EMAIL_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST,
} from "../Credentials/credentials.constants";
import { apiGet } from "app/services/apiServices";
import AirlineIntegrations from "./components/AirlineIntegrations";
import EmailIntegrations from "./components/EmailIntegrations";
import SearchInput from "app/shared/SearchInput";
import CreateEmail from "../Credentials/components/EmailCredentials/components/CreateEmail";
import Loader from "app/shared/Loader";
export default function Integrations(props: any) {
  const [activeTab, setActiveTab] = useState("1");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [gstCreds, setGSTCreds] = useState<any[]>([]);
  const [airCreds, setAirCreds] = useState<any[]>([]);
  const [emailCreds, setEmailCreds] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const gridRef = useRef<any>(null);

  const loadAllCredentials = async () => {
    setLoading(true);
    const gstCredResponse = await apiGet(
      API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST
    );
    const airCreds = await apiGet(API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST);
    const emailCreds = await apiGet(API_ENDPOINT_FETCH_EMAIL_CREDENTIALS_LIST);
    if (gstCredResponse.status) {
      setGSTCreds(gstCredResponse.data?.credentials);
    }
    if (airCreds.status) {
      setAirCreds(airCreds.data?.credentials);
    }
    if (emailCreds.status) {
      setEmailCreds(emailCreds.data?.credentials);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadAllCredentials();
  }, [userDetails.currentWorkspace]);

  const items = [
    {
      key: "1",
      label: "All Integrations",
    },
    {
      key: "2",
      label: "GST",
    },
    {
      key: "3",
      label: "Airline",
    },
    {
      key: "4",
      label: "Email",
    },
  ];
  const handleSearchWorkspace = (query: string) => {
    console.log("values is:L", query);
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  const renderScene = () => {
    switch (activeTab) {
      case "1":
        return (
          <AllIntegrations
            onSelect={(tabItem: string) => setActiveTab(tabItem)}
            loadData={loadAllCredentials}
            data={{ gst: gstCreds, air: airCreds, email: emailCreds }}
          />
        );
      case "2":
        return (
          <GSTIntegration
            ref={gridRef}
            data={gstCreds}
            loadData={loadAllCredentials}
          />
        );
      case "3":
        return (
          <AirlineIntegrations
            ref={gridRef}
            data={airCreds}
            loadData={loadAllCredentials}
          />
        );
      case "4":
        return (
          <EmailIntegrations
            ref={gridRef}
            data={emailCreds || []}
            loadData={loadAllCredentials}
          />
        );
      default:
        return (
          <AllIntegrations
            onSelect={(tabItem: string) => setActiveTab(tabItem)}
            loadData={loadAllCredentials}
          />
        );
    }
  };

  return (
    <div className="Integrations">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <Tabs
              items={items}
              tabBarStyle={{
                borderBottom: "none", // Remove the underline border
                margin: 0,
              }}
              activeKey={activeTab}
              onChange={(tabItem: string) => setActiveTab(tabItem)}
            />
          }
          rightActions={
            <div style={{ display: "flex", alignItems: "center" }}>
              {activeTab === "4" ? (
                <Button
                  type="primary"
                  size="small"
                  icon={<PlusOutlined />}
                  onClick={() => setShowModal(true)}
                  style={{ marginRight: 24 }}
                >
                  Add Email Config
                </Button>
              ) : null}
            </div>
          }
        />

        <div className="TableContainer">
          {isLoading ? <Loader /> : renderScene()}
        </div>
      </div>

      <Modal
        title="Add Email To Workspace"
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={600}
      >
        <div style={{ marginTop: 24 }}>
          <CreateEmail
            onSuccess={() => setShowModal(false)}
            onClose={() => setShowModal(false)}
          />
        </div>
      </Modal>
    </div>
  );
}
