import { apiGet, apiPost } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import { useEffect, useRef, useState } from "react";
import {
  API_ENDPOINT_EMAIL_CREDENTIAL_MAP,
  API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST,
  API_ENDPOINT_FETCH_EMAIL_CREDENTIALS_LIST,
} from "../../credentials.constants";
import Loader from "app/shared/Loader";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Button, Empty, Modal, Spin, message } from "antd";
import SearchInput from "app/shared/SearchInput";
import CreateEmail from "./components/CreateEmail";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import PageHeader from "app/shared/PageHeader";
export default function EmailCredentials(props: any) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const gridRef = useRef<any>(null);

  const columnDefs = [
    {
      field: "name",
      headerName: "Workspace",
      enableRowGroup: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      field: "pan",
      headerName: "PAN Number",
      enableRowGroup: true,
      minWidth: 200,
      filter: "agTextColumnFilter",
    },
    {
      field: "ssr_email",
      headerName: "SSR Email",
      enableRowGroup: true,
      minWidth: 200,
      editable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "email",
      headerName: "Email",
      enableRowGroup: true,
      minWidth: 240,
      editable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params: any) => {
        return params.data?.email
          ? params.data?.email
          : params.data?.email_login_pid;
      },
      valueGetter: (params: any) => {
        return params.data?.email
          ? params.data?.email
          : params.data?.email_login_pid;
      },
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: emailList.map((option: any) => option.email), // Pass only values to ag-Grid
      },
    },
    {
      field: "status",
      headerName: "Status",
      enableRowGroup: true,
      minWidth: 200,
      formatType: "MATCH",
      formatProps: {
        matchLogic: {
          matchColor: {
            ACTIVE: "green",
            PENDING: "yellow",
            INVALID: "red",
          },
          icon: {
            true: <CheckCircleFilled />,
            false: <Spin />,
            null: <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },

    {
      field: "action",
      headerName: "Action",
      enableRowGroup: true,
      editRow: true,
      focusKey: "email",
      cellRenderer: AgTableEditButton,
      cellRendererParams: {
        onClick: (params: any) => console.log(params), // Define your edit logic here
      },
    },
  ];

  useEffect(() => {
    fetchEmailCredentials();
    fetchEmailsForCluster();
  }, [userDetails.currentWorkspace]);

  const fetchEmailsForCluster = async () => {
    const response = await apiGet(API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST);
    if (response.status) {
      setEmailList(response.data);
    } else {
    }
  };

  const fetchEmailCredentials = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_EMAIL_CREDENTIALS_LIST);
    if (response.status) {
      setTableData(response.data?.credentials);
    } else {
    }
    setLoading(false);
  };

  const handleCreateSuccess = () => {
    setShowModal(false);
  };

  const handleRowEdit = async (rowData: any) => {
    console.log("rowDAta", rowData);
    let emailPID: any = emailList.find(
      (item: any) => item.email === rowData.email
    );
    const response = await apiPost(API_ENDPOINT_EMAIL_CREDENTIAL_MAP, {
      workspace_id: rowData?.workspace_id,
      email_login_pid: emailPID?.email_login_pid,
      ssr_email: rowData?.ssr_email,
      pan: rowData?.pan,
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const handleExportTableData = () => {
    console.log("calling export funciton", gridRef);

    if (gridRef.current && gridRef.current.exportDataToExcel) {
      gridRef.current.exportDataToExcel();
    }
  };

  return (
    <div className="ScreenContainer">
      {props.componentType ? null : (
        <PageHeader
          title="Email Credentials"
          rightActions={
            <div>
              <Button
                type="primary"
                size="small"
                icon={<ExportOutlined />}
                onClick={handleExportTableData}
                style={{ marginRight: 12 }}
              >
                Export
              </Button>
              <Button
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => setShowModal(true)}
              >
                Add Email
              </Button>
            </div>
          }
        />
      )}
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={columnDefs}
            onRowSaved={handleRowEdit}
            footer={false}
            ref={gridRef}
            groupPanel
            autoResize
          />
        )}
      </div>

      <Modal
        title="Add Email To Workspace"
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={600}
      >
        <div style={{ marginTop: 24 }}>
          <CreateEmail
            onSuccess={handleCreateSuccess}
            onClose={() => setShowModal(false)}
          />
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}
