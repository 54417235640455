import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import "../AgTable.scss";
// import "../AgTheme.css";

import { Button, Empty, message, Tag } from "antd";
import {
  DownloadOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { handleAgCellDataFormat, handleCellRenderer } from "../AgServerUtility";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PDFViewerTool from "../HelperTools/PDFViewerTool";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import Loader from "app/shared/Loader";
import PDFViewerToolV2 from "../HelperTools/PDFViewerTool/pdfViewerV2";
import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import {
  API_ENDPOINT_AG_TABLE_LIST_ALL_VIEW,
  API_ENDPOINT_AG_TABLE_LOAD_VIEW,
  API_ENDPOINT_AG_TABLE_SAVE_VIEW,
  API_ENDPOINT_AG_TABLE_UPDATE_VIEW,
} from "../AgTable.constants";
import { agTableRowSize, tableSchema } from "app/config/States/agtable";
import MasterDetail from "./MasterDetail";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
const AgTableServer = forwardRef((props, ref) => {
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [userDetails, _] = useRecoilState(userInfo);
  const [isLoading, setLoading] = useState(false);
  const [grandTotalRow, setGrandTotalRow] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingView, setLoadingView] = useState(false);
  const [initialColumnState, setInitialColumnState] = useState([]);
  const [tableRowSize, setTableRowSize] = useRecoilState(agTableRowSize);

  const gridRef = useRef();

  const processColumnDefs = (columnDefs) => {
    return columnDefs.map((obj) => ({
      ...obj, // Spread the original object
      ...(obj.formatType ? handleCellRenderer() : {}), // Add the new key-value pair
    }));
  };

  const fetchServerSide = useCallback(async () => {
    // Update the grid data

    gridApi.setServerSideDatasource({
      getRows: function (params) {
        // Use the fetched data to update the grid
        fetch(props.endpoint || "", {
          method: "POST",
          body: JSON.stringify({
            ...params.request,
            ...props.serverPayload,
            useWorkspace: props.useWorkspace,
          }),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
            workspaceId: localStorage.getItem("currentWorkspace"),
            platform: process.env.APP_ENV || "cleartrip",
          },
        })
          .then((httpResponse) => httpResponse.json())
          .then((response) => {
            params.success({
              rowData: response.data.rows,
              rowCount: response.data.lastRow,
              ...(response.data.pivotResultFields &&
              response.data.pivotResultFields.length > 0
                ? { pivotResultFields: response.data.pivotResultFields }
                : {}),
            });

            setGrandTotalRow(response.data.grandTotalRow);
            setRowData(response.data.rows);
            // handleColumnRowGroupChanged(params);
          })
          .catch((error) => {
            console.error(error);
            params.fail();
          });
        setLoading(false);
      },
    });
  }, [gridApi, props.useWorkspace, userDetails.currentWorkspace]);

  useEffect(() => {
    console.log("props.useWorkspace", props.useWorkspace);
    if (gridApi) {
      fetchServerSide();
    }
  }, [
    fetchServerSide,
    gridApi,
    userDetails.currentWorkspace,
    props.useWorkspace,
  ]);

  const onGridReady = useCallback(async (params) => {
    const columnApi = params.columnApi;
    setInitialColumnState(columnApi.getColumnState());
    setGridApi(params.api);
    // params.api.setServerSideDatasource(datasource);
    let processDefs = processColumnDefs(props.columnDefs);

    setColumnDefs([
      // {
      //   headerName: "Expand All Data",
      //   cellRendererSelector: (params) => {
      //     return {
      //       component: "agGroupCellRenderer",
      //       params: {
      //         innerRenderer: (params) => {
      //           return `Show All`;
      //         },

      //         suppressCount: true,
      //         suppressDoubleClickExpand: true,
      //       },
      //     };
      //   },
      // },
      ...processDefs,
    ]);
  }, []);

  const excelStyles = [
    {
      id: "header",
      interior: {
        color: "#f5f6f9", // Gray background
        pattern: "Solid",
      },
      font: {
        bold: true,
        size: 12, // Font size
        color: "#283b5a", // Blue font color
      },
      alignment: {
        horizontal: "Center",
        vertical: "Center",
      },
    },
  ];

  const exportDataToExcel = useCallback(() => {
    gridRef.current.api.exportDataAsExcel({
      allColumns: true,
      fileName: "export.xlsx",
      sheetName: "Data",
      columnWidth: (params) => {
        return params.column.getColDef().headerName.length * 10;
      },
      rowHeight: 25,
    }); // Access grid API from the ref
  }, [gridRef]);

  const getGridState = () => {
    const gridApi = gridRef.current?.api;
    const columnApi = gridRef.current.columnApi;

    return {
      columnState: columnApi.getColumnState(),
      sortState: columnApi
        .getAllGridColumns()
        .map((col) => ({
          colId: col.getColId(),
          sort: col.getSort(),
        }))
        .filter((col) => col.sort),
      filterState: gridApi.getFilterModel(),
      pivotMode: columnApi.isPivotMode(),
      rowGroupColumns: columnApi.getRowGroupColumns().map((col) => col.colId),
      pivotColumns: columnApi.getPivotColumns().map((col) => col.colId),
    };
  };

  const saveGridView = async (viewName, moduleId) => {
    const gridState = getGridState();
    const payload = {
      name: viewName,
      gridState: gridState,
      moduleId: moduleId,
    };
    const response = await apiPost(API_ENDPOINT_AG_TABLE_SAVE_VIEW, payload);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Awesome👍 , your view is created",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not save view, please try again",
      });
    }
  };

  const updateGridView = async (viewId, moduleId) => {
    const gridState = getGridState();
    const payload = {
      viewId: viewId,
      gridState: gridState,
      moduleId: moduleId,
    };
    const response = await apiPut(
      `${API_ENDPOINT_AG_TABLE_UPDATE_VIEW}${viewId}/update`,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Awesome👍 , your view is updated",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not save view, please try again",
      });
    }
  };

  const loadGridView = async (viewId, moduleId) => {
    const gridApi = gridRef.current.api;
    const columnApi = gridRef.current.columnApi;
    setLoadingView(true);

    if (viewId === "DEFAULT") {
      if (columnApi) {
        gridApi.resetColumnState();

        columnApi.applyColumnState({
          state: initialColumnState,
          applyOrder: false,
        });
        setLoadingView(false);

        columnApi.setRowGroupColumns([]); // Set row group columns
        columnApi.setPivotColumns([]); // Set pivot columns
        columnApi.setPivotMode(false); // Set pivot mode
      }

      // Apply sort and filter states
      if (gridApi) {
        gridApi.setFilterModel({}); // Apply filter model

        gridApi.refreshClientSideRowModel(); // Refresh the grid to apply the sorting
      }

      return;
    }
    const response = await apiGet(
      `${API_ENDPOINT_AG_TABLE_LOAD_VIEW}${moduleId}/${viewId}`
    );
    if (response.status && response.data) {
      const view = response.data;

      // Apply column state
      if (columnApi) {
        columnApi.applyColumnState({
          state: view.state.columnState,
          applyOrder: true,
        }); // Apply column state
        columnApi.setRowGroupColumns(view.state.rowGroupColumns); // Set row group columns
        columnApi.setPivotColumns(view.state.pivotColumns); // Set pivot columns
        columnApi.setPivotMode(view.state.pivotMode); // Set pivot mode
      }

      // Apply sort and filter states
      if (gridApi) {
        gridApi.setFilterModel(view.state.filterState); // Apply filter model

        // Apply sorting manually
        view.state.sortState.forEach((sort) => {
          gridApi.getColumnState().forEach((column) => {
            if (column.colId === sort.colId) {
              column.sort = sort.sort;
            }
          });
        });
        gridApi.refreshClientSideRowModel(); // Refresh the grid to apply the sorting
      }
    }
    setLoadingView(false);
  };

  React.useImperativeHandle(ref, () => ({
    exportDataToExcel,
    getGridState,
    saveGridView,
    loadGridView,
    updateGridView,
  }));

  const statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left",
      },
      {
        statusPanel: "agTotalRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agFilteredRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agSelectedRowCountComponent",
        align: "right",
      },
      {
        statusPanel: "agAggregationComponent",
        align: "right",
      },
    ],
  };

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "pdfRenderer",
          labelDefault: "PDF View",
          labelKey: "pdfRenderer",
          iconKey: "pdf-file",
          width: 500,
          toolPanel: props.pdfV2 ? PDFViewerToolV2 : PDFViewerTool,
          toolPanelParams: {
            dataKey: props.pdfDataKey,
            pdfData: props.pdfData,
          },
        },
      ],
    }),
    [props.pdfData]
  );

  function findColumnDefByField(columnDefs, field) {
    let result = null;

    // Function to recursively search for the column definition
    function search(columns) {
      for (const column of columns) {
        if (column.field === field) {
          result = column;
          return; // Stop searching once we find the column
        }
        // Recursively search children
        if (column.children) {
          search(column.children);
        }
      }
    }

    search(columnDefs);
    return result;
  }

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      minWidth: 100,
      flex: 1,
    };
  }, []);
  const detailCellRenderer = useCallback(MasterDetail, []);

  return (
    <div className="AgTable">
      <div className="ag-theme-alpine">
        <div style={{ height: "100%", position: "relative" }}>
          {loadingView ? (
            <div className="CustomLoading">
              <Loader />
            </div>
          ) : null}

          <AgGridReact
            rowModelType="serverSide"
            columnDefs={columnDefs || []}
            pagination={props.pagination}
            paginationPageSize={tableRowSize}
            rowSelection="multiple"
            suppressCellSelection={true}
            // masterDetail={true}
            // detailCellRenderer={detailCellRenderer}
            cacheBlockSize={tableRowSize}
            resizable={true}
            enablePivot={true}
            enableSorting={true}
            // pivotMode={true}
            excelStyles={excelStyles}
            enableFilter={true}
            floatingFilter
            groupMultiAutoColumn={true}
            rowGroupPanelShow="always"
            pivotPanelShow="always"
            pivotColumnGroupTotals="before"
            onGridReady={onGridReady}
            // defaultColDef={defaultColDef}
            suppressAggFuncInHeader={true}
            ref={gridRef}
            overlayLoadingTemplate={
              '<span class="ag-overlay-loading-center">Loading...</span>'
            }
            overlayNoRowsTemplate={
              '<span class="ag-overlay-loading-center">No Rows to Show</span>'
            }
            // statusBar={statusBar}
            // grandTotalRow={"bottom"}
            // serverSidePivotResultFieldSeparator="_"
            sideBar={sideBar}
            // pinnedTopRowData={[]}
            pinnedBottomRowData={
              grandTotalRow && Object.keys(grandTotalRow).length > 0
                ? [grandTotalRow]
                : []
            }
            // pinnedTopRowData={grandTotalRow ? [grandTotalRow] : []}
            processPivotResultColDef={(colDef) => {
              // Apply customizations based on the field or other criteria
              if (colDef.colId.includes("amount")) {
                colDef.cellRenderer = "customCellRenderer";
                colDef.cellRendererParams = {
                  customParam: "value",
                };
              }
              // Customize other pivot columns as needed
              return colDef;
            }}
            onColumnPivotModeChanged={(params) => {
              console.log("pivot col chjange", params);
              const columnApi = gridRef.current.columnApi;

              const columnState = columnApi.getColumnState();

              const updatedState = columnState.map((colState) => {
                const originalColDef = columnDefs.find((def) =>
                  colState.colId.includes(def.field)
                );

                if (originalColDef && colState.pivotValueColumn) {
                  return {
                    ...colState,
                    cellRenderer: originalColDef.cellRenderer,
                    cellRendererParams: originalColDef.cellRendererParams,
                  };
                }

                return colState;
              });

              console.log("updatedState", updatedState);

              // Apply the updated state back to the grid
              columnApi.applyColumnState(updatedState);

              // Optionally refresh the grid if necessary
              params.api.refreshCells({ force: true });
            }}
            autoGroupColumnDef={{
              headerName: "Group",
              cellRenderer: "agGroupCellRenderer",
              cellRendererParams: {
                innerRenderer: (params) => {
                  // Ensure 'params.node.field' is correct for your grouped data
                  const groupField = params.node.field;

                  // Find the original column definition based on the group field
                  const originalColumn = findColumnDefByField(
                    columnDefs,
                    groupField
                  );

                  // Render group header content
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center", // Corrected from `justifyItems` to `justifyContent`
                      }}
                    >
                      {handleAgCellDataFormat(
                        originalColumn?.formatType || "",
                        params.value,
                        originalColumn?.formatProps || "",
                        params.data || {}
                      )}
                      {params?.data[`${params.node.field}_count`] ? (
                        <Typography
                          style={{
                            marginLeft: 4,
                            color: colorPicker("neutral.700"),
                          }}
                          weight={600}
                        >
                          {` (${params?.data[`${params.node.field}_count`]})`}
                        </Typography>
                      ) : null}
                    </div>
                  );
                },
              },
            }}
          />
        </div>
      </div>
      {contextHolder}
    </div>
  );
});

export default AgTableServer;
