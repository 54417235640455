import { AgGridReact } from "ag-grid-react";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AgTableClient, AgTableServer } from "app/shared/AgTable";
import { apiGet } from "app/services/apiServices";
import { handleCellRenderer } from "app/shared/AgTable/AgServerUtility";
import Loader from "app/shared/Loader";
import { Button } from "antd";
import { currentPDFData, userInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { API_ENDPOINT_RECON_ZA_AIRLINE_DATA } from "app/scenes/AppTour/onboarding.constants";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
import { tableSchema } from "app/config/States/agtable";
import { getProcessedDefinition } from "app/utils/generic.helper";

const ChildComponent = forwardRef((props: any, ref) => {
  const [pdfViewerData, setPdfViewerData] = useState("");
  const [tableSchemaInfo, setTableSchema] = useRecoilState<any>(tableSchema);

  const openSidebarRenderer = (params: any) => {
    setPdfViewerData(params?.data);
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };
  const initialColDef = [
    {
      headerName: "Invoice",
      field: "Invoice_link",
      filter: "agTextColumnFilter",
      invoiceLink: "html.pdf",
      cellRenderer: ToolParamInvoker,
      cellRendererParams: (params: any) => {
        return {
          label: "View Invoice",
          handleClick: openSidebarRenderer,
          data: params.data?.Invoice_link || params?.value,
        };
      },
    },
    {
      headerName: "Invoice Status",
      field: "Invoice_Status",
      filter: "agTextColumnFilter",
      enablePivot: true,
      enableRowGroup: true,

      formatType: "MATCH",
      //@ts-ignore
      enablePivot: true,
      formatProps: {
        matchLogic: {
          matchColor: {
            "Invoice Received": "green",
            "Invoice Not Received": "red",
          },
          icon: {
            "Invoice Received": <CheckCircleFilled />,
            "Invoice Not Received": <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },

    {
      headerName: "Invoice Data",
      children: [
        {
          headerName: "Vendor",
          field: "Vendor",
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          ...handleCellRenderer(),
          formatType: "AIRLINE",
        },
        {
          headerName: "Agency Name",
          field: "Agency_Name",
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          ...handleCellRenderer(),
        },
        {
          headerName: "Agency Invoice Number",
          field: "Agency_Invoice_Number",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          enableRowGroup: true,
        },
        {
          headerName: "Customer GSTIN State",
          field: "Customer_GSTIN",
          filter: "agTextColumnFilter",
          formatType: "GSTIN_STATE",
          ...handleCellRenderer(),
          enableRowGroup: true,
          valueGetter: (params: any) =>
            params.data?.["Customer_GSTIN"]?.substring(0, 2),
        },
        {
          headerName: "Customer GSTIN",
          field: "Customer_GSTIN",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          enableRowGroup: true,
        },
        {
          headerName: "Customer Name",
          field: "Customer_Name",
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          ...handleCellRenderer(),
        },

        {
          headerName: "Workspace",
          field: "Workspace",
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          ...handleCellRenderer(),
        },
        {
          headerName: "Ticket/PNR",
          field: "Ticket_PNR",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          enableRowGroup: true,
        },
        {
          headerName: "Ticket Number",
          field: "Ticket_Number",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          enableRowGroup: true,
        },

        // {
        //   headerName: "Invice Count",
        //   field: "InvoiceCount",
        //   filter: "agNumberColumnFilter",
        //   enableRowGroup: true,
        //   enableValue: true,
        //   ...handleCellRenderer(),
        // },
        {
          headerName: "PNR",
          field: "PNR",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          enableRowGroup: true,
        },
        {
          headerName: "Location",
          field: "Location",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
        },
        {
          headerName: "Booking Date",
          field: "Booking_Date",
          filter: "agDateColumnFilter",
          formatType: "DATE",
          ...handleCellRenderer(),
        },
        {
          headerName: "Main Tag",
          field: "MainTag",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          enableRowGroup: true,
        },
      ],
    },
    {
      headerName: "2B Data",
      children: [
        {
          headerName: "AI Invoice Note Number",
          field: "AI_InvoiceNoteNumber",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          enableRowGroup: true,
        },
        {
          headerName: "AI - Invoice/Note Date",
          field: "AI_InvoiceNoteDate",
          filter: "agDateColumnFilter",
          formatType: "DATE",
          ...handleCellRenderer(),
        },
        {
          headerName: "AI_CGST",
          field: "AI_CGST",
          filter: "agNumberColumnFilter",
          formatType: "AMOUNT",
          enableRowGroup: true,
          enableValue: true,
          ...handleCellRenderer(),
        },
        {
          headerName: "AI - SGST",
          field: "AI_SGST",
          filter: "agNumberColumnFilter",
          formatType: "AMOUNT",
          enableRowGroup: true,
          enableValue: true,
          ...handleCellRenderer(),
        },
        {
          headerName: "AI - IGST",
          field: "AI_IGST",
          filter: "agNumberColumnFilter",
          formatType: "AMOUNT",
          enableRowGroup: true,
          enableValue: true,
          ...handleCellRenderer(),
        },
        {
          headerName: "AI - Tax Rate",
          field: "AI_TaxRate",
          filter: "agNumberColumnFilter",
          formatType: "AMOUNT",
          enableRowGroup: true,
          enableValue: true,
          ...handleCellRenderer(),
        },
        {
          headerName: "AI - Taxable",
          field: "AI_Taxable",
          filter: "agNumberColumnFilter",
          formatType: "AMOUNT",
          enableRowGroup: true,
          enableValue: true,
          ...handleCellRenderer(),
        },
        {
          headerName: "AI - Total Amount",
          field: "AI_TotalAmount",
          filter: "agNumberColumnFilter",
          formatType: "AMOUNT",
          enableRowGroup: true,
          enableValue: true,
          ...handleCellRenderer(),
        },
        {
          headerName: "AI - Vendor GSTIN",
          field: "AI_VendorGSTIN",
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          ...handleCellRenderer(),
        },
        {
          headerName: "Origin",
          field: "Origin",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
        },
        {
          headerName: "AI - Document Type",
          field: "AI_DocumentType",
          filter: "agTextColumnFilter",
          enablePivot: true,
          enableRowGroup: true,
          ...handleCellRenderer(),
        },
      ],
    },
    {
      headerName: "SOTO Status",
      field: "SOTO_Status",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
    },

    {
      headerName: "GST Exempted",
      field: "GST_Exempted",
      filter: "agTextColumnFilter",
    },
    // {
    //   headerName: "Invoice Link",
    //   field: "Invoice_link",
    //   filter: "agTextColumnFilter",
    // },
    {
      headerName: "Provision Status",
      field: "Provision_Status",
      filter: "agTextColumnFilter",
      formatType: "MATCH",
      enableRowGroup: true,
      formatProps: {
        matchLogic: {
          matchColor: {
            "Invoice Received": "green",
            "Not Billed": "red",
          },
          icon: {
            "Invoice Received": <CheckCircleFilled />,
            "Not Billed": <CloseCircleFilled />,
          },
        },
        defaultText: "Not Available",
      },
    },
  ];

  useImperativeHandle(ref, () => ({
    createNewView(viewName: string) {
      gridRef.current?.saveGridView(viewName, props.moduleId);
    },
    loadTableView(viewId: string) {
      gridRef.current?.loadGridView(viewId, props.moduleId);
    },
    updateTableView(viewId: string) {
      gridRef.current?.updateGridView(viewId, props.moduleId);
    },
    exportDataToExcel() {
      gridRef.current?.exportDataToExcel();
    },
  }));
  const [columnDefs, setColumnDefs] = useState(initialColDef);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const gridRef: any = useRef();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [recoilPDFData, setRecoilPdfData] =
    useRecoilState<string>(currentPDFData);
  useEffect(() => {
    setColumnDefs(initialColDef);
  }, []);

  const getProcessedDefinitions = () => {
    const columnObject = tableSchemaInfo.find(
      (item: any) => item.moduleId === "AIRLINE_RECON"
    );
    let columnArray = columnObject?.state?.columnDefs || [];
    let finalDefs = getProcessedDefinition(
      columnArray,
      "SERVER",
      setRecoilPdfData
    );
    console.log("final Defs:", finalDefs);
    return finalDefs;
  };

  return (
    <div
      className="UnReconContainer"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{ flex: 1, width: "100%", height: "100%" }}
      >
        {isLoading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader title="fetching your booking data from Makemytrip" />
          </div>
        ) : (
          <AgTableServer
            //@ts-ignore
            rowData={[]}
            columnDefs={getProcessedDefinitions()}
            endpoint={API_ENDPOINT_RECON_ZA_AIRLINE_DATA}
            // endpoint={"http://192.168.1.59:7001/api/recon/hotel"}
            pdfDataKey="Invoice_link"
            enablePDFViewer
            grandTotalRow
            pdfV2
            ref={gridRef}
            showStatusBar
            useWorkspace={
              userDetails?.currentWorkspace?.name === "MMT Admin" ? false : true
            }
          />
        )}
      </div>
    </div>
  );
});

export default ChildComponent;
