import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import Loader from "app/shared/Loader";
import { Empty, Tabs } from "antd";
import Typography from "app/shared/Typography";
import EmptyState from "app/shared/EmptyState";
import { useNavigate } from "react-router-dom";
export default function Dashboard(props: any) {
  const [activeKey, setActiveKey] = useState("1");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    if (!userDetails.is_admin) navigate("/flights");
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [userDetails]);

  const getTabView = () => {
    let dashboards = [
      {
        report_name: "All Vendors",
        embedurl:
          "https://assets.finkraft.ai/open-view/103074000027682072/8f3c76fe9a7f779aebbc29aabcd6a3c3",
      },
      {
        report_name: "All Vendor Report",
        embedurl:
          "https://assets.finkraft.ai/open-view/103074000027553467/6c7a32a5c5feb899155f9e7a5bb5475f",
      },
    ];
    let tabs: any = [];
    let id = 1;
    dashboards.forEach((dashboard: any) => {
      tabs.push({
        title: dashboard.report_name,
        id: id,
        type: "VIEW",
        viewData: dashboard.embedurl,
      });
      id = id + 1;
    });

    return tabs;
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <Tabs
              items={[
                {
                  key: "1",
                  label: "Dashboard",
                },
                {
                  key: "2",
                  label: "Dashboard With Error",
                },
              ]}
              tabBarStyle={{
                borderBottom: "none", // Remove the underline border
                margin: 0,
              }}
              activeKey={activeKey}
              onChange={(tabItem: string) => setActiveKey(tabItem)}
            />
          }
          // leftActions={
          //   <CustomReconTabs
          //     activeKey={activeKey}
          //     title="Report"
          //     onTabChange={(tabKey: any) => setActiveKey(tabKey)}
          //     onTabModify={(tabs: any) =>
          //       setTabView({ ...tabViews, dashboard: tabs })
          //     }
          //     hideAddTab
          //     hideRemove
          //     defaultTabs={getTabView()}
          //     width={200}
          //   />
          // }
        />

        <div className="TableContainer">
          <iframe
            height="100%"
            style={{ width: "100%" }}
            scrolling="no"
            title="Iframe"
            src={
              activeKey === "1"
                ? "https://analytics.zoho.in/open-view/103074000029079389/e61c0cc77b762e70705fbabbe2b24c65"
                : "https://analytics.zoho.in/open-view/103074000028990582/1417ab2b1c1b7ec53f62d2b6723c70e3"
            }
          ></iframe>
        </div>
      </div>
    </div>
  );
}
