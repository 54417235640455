import { useEffect, useState } from "react";
import "./AppTour.scss";
import { Button, ConfigProvider, Input, Modal, Steps } from "antd";
import Typography from "app/shared/Typography";
import Greeting from "./components/Greeting";
import { Outlet, useNavigate } from "react-router-dom";
import OnboardAirlineReconView from "./components/OnboardAirlineReconView";
import Tour, { TourProvider } from "@reactour/tour";
import OnboardConnectForm from "./components/OnboardConnectForm";
import OnboardUnReconciled from "./components/OnboardUnReconcilied";
import SitRelax from "./components/SitRelax";
import ValidateCredentials from "./components/ValidateCredentials";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { API_ENDPOINT_GET_USER_INFO } from "../Auth/auth.constants";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiGet } from "app/services/apiServices";
import Rocketlane from "./components/Rocketlane";
import OnboardHotelReconView from "./components/OnboardHotelReconView";
import UploadBookingData from "./components/UploadBookingData";
import colGroupGif from "static/gifs/column_group.gif";
import viewInvoiceGIF from "static/gifs/view_invoice.gif";
import enablePivotGIF from "static/gifs/enable_pivot.gif";
import modifyPivotGIF from "static/gifs/modify_pivot.gif";
import ReconDashboardDemo from "./components/ReconDashboardDemo";
import { colorPicker } from "app/utils/color.helper";
import { LineWeight } from "@mui/icons-material";
import FinkraftLogo from "static/images/FinLogo.png";
import FinkAtom from "app/shared/FinkAtom";
import TourContent from "app/shared/TourContent";
import AppTourStart from "./components/AppTourStart";
import BookingDataPreview from "../OnboardingV2/components/BookingDataPreview";
import LandingDataPage from "../OnboardingV2/components/LandingDataPage";

const stepList = [
  {
    title: "Greeeting",
  },
  {
    title: "Reconciliation Dashboard Demo",
  },
  {
    title: "Flight Reconcilation Demo",
  },

  {
    title: "Hotel Reconcilation Demo",
  },

  {
    title: "That's all",
  },
];
export default function AppTour(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [currentTourStep, setCurrentTourStep] = useState(0);
  const [disableNextTour, setDisableNextTour] = useState(false);
  const [visitedStep, setVisitedStep] = useState(0);
  const [userDetails, setUserDetails] = useRecoilState(userInfo);
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchUserInfo = async () => {
    // const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    // if (response.status) {
    //   setUserDetails({
    //     ...response.data,
    //     currentWorkspace: response.data.workspaces[0],
    //     clusterId: response.data.cluster_id,
    //   });
    // }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {}, []);
  const addHighlighter = (domClassName, targetArrayId = null) => {
    let domToAppend = document.getElementsByClassName(domClassName);

    let selctedDom =
      domToAppend.length > 1
        ? domToAppend[targetArrayId ? targetArrayId : 0]
        : domToAppend;
    var div = document.createElement("div");
    div.className = "domHighlighterCircle";
    div.id = "domHighlighterCircle";

    // Create and append three ripple divs inside the new div
    for (var i = 0; i < 3; i++) {
      var rippleDiv = document.createElement("div");
      rippleDiv.className = "domHighlighterCircle-ripple";
      div.appendChild(rippleDiv);
    }

    selctedDom.appendChild(div);
  };

  const handleStepNext = (stepIndex) => {
    let nextStepInfo = steps[stepIndex];
    if (stepIndex > 3) {
      setActiveStep(2);
    } else {
      if (nextStepInfo.targetDom) {
        let targetDomSplit = nextStepInfo.targetDom.split("~");
        addHighlighter(targetDomSplit[0], targetDomSplit[1]);
        setDisableNextTour(true);
      }
      setCurrentTourStep(stepIndex);
    }
  };

  const renderForms = () => {
    switch (activeStep) {
      case 0:
        return (
          <TourProvider
            showCloseButton={false}
            showBadge={false}
            showDots={false}
            defaultOpen={false}
            steps={[
              {
                selector: "#root",
                content: (
                  <TourContent
                    speak={`Hi, I am Fink AI. I will be giving you a quick tour of our
                product. I will be taking you through our offerings and
                core features like high accuracy Reconcilation, dat-driven
                decision making and lot more.`}
                    text={
                      "Hi!, I am Fink A.I. I am here to assist with your query"
                    }
                    goNext={() => setCurrentTourStep(currentTourStep + 1)}
                  />
                ),
              },
              {
                selector: "#solcard1",
                content: (
                  <TourContent
                    speak={`Providing complete GST ITC anlaytics is one of our core feautre offering for our partners and clients. This includes anlysing your business segment , maximizing input tax credit and identify missing recors. `}
                    text={"What is GST ITC Anlytics?"}
                    goNext={() => setCurrentTourStep(currentTourStep + 1)}
                  />
                ),
              },
              {
                selector: "#solcard2",
                content: (
                  <TourContent
                    speak={`We are proud of our Reconciliation accuracy. It has help almost everytime to get the exact record matched with all possible error for your gst claim`}
                    text={"How good is our reconciliation?"}
                    goNext={() => setCurrentTourStep(currentTourStep + 1)}
                  />
                ),
              },
              {
                selector: "#solcard3",
                content: (
                  <TourContent
                    speak={`Communication and input during the reconciliation process can be very painful but don't worry we have got you. We provide you capability of creating custom alerts and schedules and more.`}
                    text={"How Follow up works?"}
                  />
                ),
              },
            ]}
            currentStep={currentTourStep}
            styles={{
              popover: (base) => ({
                ...base,
                "--reactour-accent": "#ef5a3d",
                borderRadius: radius,
              }),
              maskArea: (base) => ({ ...base, rx: radius }),
              maskWrapper: (base) => ({ ...base, color: "#00000073" }),

              badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
              controls: (base) => ({ ...base, marginTop: 100 }),
              close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
            }}
            // setCurrentStep={handleStepNext}
          >
            <Greeting
              goNext={() => {
                setActiveStep(activeStep + 1);
                setVisitedStep(activeStep + 1);
              }}
            />
          </TourProvider>
        );

      case 1:
        return (
          <ReconDashboardDemo
            goNext={() => {
              setActiveStep(activeStep + 1);
              setVisitedStep(activeStep + 1);
            }}
          />
        );

      case 2:
        return (
          <TourProvider
            steps={steps}
            defaultOpen={false}
            currentStep={currentTourStep}
            styles={{
              popover: (base) => ({
                ...base,
                "--reactour-accent": "#ef5a3d",
                borderRadius: radius,
              }),
              maskArea: (base) => ({ ...base, rx: radius }),
              maskWrapper: (base) => ({ ...base, color: "#00000073" }),

              badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
              controls: (base) => ({ ...base, marginTop: 100 }),
              close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
            }}
            // setCurrentStep={handleStepNext}
          >
            <OnboardAirlineReconView
              goNext={() => {
                setActiveStep(activeStep + 1);
                setVisitedStep(activeStep + 1);
              }}
              onColGroup={(colData) => {
                console.log("columnData", colData);
                if (colData && colData.colId === "Vendor Name") {
                  setCurrentTourStep(currentTourStep + 1);
                  setDisableNextTour(false);
                }
              }}
            />
          </TourProvider>
        );
      case 3:
        return (
          <OnboardHotelReconView
            goNext={() => {
              setActiveStep(activeStep + 1);
              setVisitedStep(activeStep + 1);
            }}
          />
        );
      case 4:
        return (
          <LandingDataPage
            goNext={() => {
              setActiveStep(activeStep + 1);
              setVisitedStep(activeStep + 1);
            }}
            noHeader
          />
        );

      case 5:
        return <BookingDataPreview noHeader />;
    }
  };

  const radius = 10;

  const steps = [
    {
      selector: ".ag-theme-alpine",
      content:
        "Table view for your Reconciliation data to visualize meaningful insights",
    },
    {
      selector: ".ag-column-drop-wrapper",
      content:
        "You can use this drop area to drag any column in order to group data in table",
    },

    {
      selector: ".ag-theme-alpine",
      content: () => (
        <div>
          <h4>View Invoice</h4>
          <p>
            Click on the <b>Invoice</b> column as show below to view invoice
          </p>
          <img src={viewInvoiceGIF} style={{ width: "100%", marginTop: 24 }} />
        </div>
      ),
    },

    {
      selector: ".ag-theme-alpine",
      content: () => (
        <div>
          <h4>Let's try with an example</h4>
          <p>
            Can you drag and drop the <b>Vendor Name</b> column as show below
          </p>
          <img
            src={colGroupGif}
            style={{
              width: "100%",
              marginTop: 24,
            }}
          />
        </div>
      ),
    },
    {
      selector: ".ag-theme-alpine",
      content: () => (
        <div>
          <h4>Pivot Mode</h4>
          <p>
            You can manipulate data as per your need, click on <b>Columns</b>{" "}
            label on right side of table as show below and enable pivot
          </p>
          <img
            src={enablePivotGIF}
            style={{
              width: "100%",
              marginTop: 24,
              maxHeight: 400,
              objectFit: "contain",
            }}
          />
        </div>
      ),
    },

    {
      selector: ".ag-theme-alpine",
      content: () => (
        <div>
          <h4>Play with Pivot Mode</h4>
          <p>
            You can see some example as show below how to modify the table in
            pivot mode
          </p>
          <img
            src={modifyPivotGIF}
            style={{
              width: "100%",
              marginTop: 24,
              maxHeight: 600,
              objectFit: "contain",
            }}
          />
        </div>
      ),
    },
    // {
    //   selector: ".ag-theme-alpine",
    //   content:
    //     "Now, It's time to drag the Vendor Name column to group it and view insight",
    //   targetDom: "ag-header-cell~1",
    // },
    // {
    //   selector: ".ag-theme-alpine",
    //   content:
    //     "You can now click any of the grouped row to get summarized data",
    // },
  ];

  const handleChangeStep = (newStepId) => {
    if (newStepId <= visitedStep) {
      setActiveStep(newStepId);
    }
  };

  const { Step } = Steps;

  return (
    <div className="AppTour">
      <div className="StepContainer">
        <div className="AppLogo">
          <img src={FinkraftLogo} alt="FinkLogo" />
        </div>

        <ConfigProvider
          theme={{
            components: {
              Steps: {
                /* here is your component tokens */
              },
            },
          }}
        >
          <Steps
            current={activeStep}
            direction="vertical"
            onChange={handleChangeStep}
          >
            {stepList.map((stepInfo, key) => {
              return (
                <Step
                  title={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={{
                          color: colorPicker(
                            activeStep === key ? "primary.800" : "neutral.900"
                          ),
                          lineHeight: "20px",
                        }}
                        variant="xs"
                      >
                        Step {key + 1}
                      </Typography>
                      <Typography
                        style={{
                          color: colorPicker(
                            activeStep === key ? "primary.900" : "neutral.800"
                          ),
                          lineHeight: 1,
                        }}
                        weight={500}
                        variant="caption"
                      >
                        {stepInfo.title}
                      </Typography>
                    </div>
                  }
                />
              );
            })}
          </Steps>
        </ConfigProvider>
      </div>
      <div className="ContentContainer">
        <div className="DetailContainer">{renderForms()}</div>
      </div>

      <Modal closable={false} open={false} footer={false} width={800}>
        <AppTourStart />
      </Modal>
    </div>
  );
}
