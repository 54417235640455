import apptourRoutes from "app/scenes/AppTour/apptour.routes";
import SampleReconViewAGTable from "app/scenes/AppTour/components/SampleReconView/sampleGSTTableView";
import authRoute from "app/scenes/Auth/auth.routes";
import AcknowledgeApproval from "app/scenes/Auth/components/ApprovalAcknowledge";
import PageNotFound from "app/scenes/Common/PageNotFound/PageNotFound";
import HotelBookingView from "app/scenes/HotelBookingView";
import SingleInvoiceUpload from "app/scenes/HotelBookingView/components/SingleInvoiceUpload";
import HotelBookingViewClient from "app/scenes/HotelBookingViewClient/HotelBookingViewClient";
import Onboarding from "app/scenes/Onboarding";
import onboadingRoutes from "app/scenes/Onboarding/onboading.routes";
import onboardingRoutes from "app/scenes/OnboardingV2/onboarding.routes";
import masterRoutes from "app/shared/MasterLayout/master.routes";

const childRoutes = [authRoute, masterRoutes, onboadingRoutes, apptourRoutes];
// const childRoutes = [authRoute];

const routes = [
  ...childRoutes,
  {
    path: "*",
    element: <PageNotFound />,
    strict: true,
  },
  {
    path: "/user/acknowledgement/:token",
    element: <AcknowledgeApproval />,
    exact: true,
  },
  {
    path: "/hotel_booking2",
    element: <HotelBookingView />,
    exact: true,
  },
  {
    path: "/hotel_booking/upload",
    element: <SingleInvoiceUpload />,
    exact: true,
  },
  {
    path: "/hotel_booking",
    element: <HotelBookingViewClient />,
    exact: true,
  },

  onboardingRoutes,
];

export default routes;
