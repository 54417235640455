import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import "../AgTable.scss";
// import "../AgTheme.css"
import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { handleAgCellDataFormat, handleCellRenderer } from "../AgUtility";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PDFViewerTool from "../HelperTools/PDFViewerTool";
import PDFViewerToolV2 from "../HelperTools/PDFViewerTool/pdfViewerV2";
import {
  API_ENDPOINT_AG_TABLE_LOAD_VIEW,
  API_ENDPOINT_AG_TABLE_SAVE_VIEW,
  API_ENDPOINT_AG_TABLE_UPDATE_VIEW,
} from "../AgTable.constants";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import Loader from "app/shared/Loader";

const detailCellRendererParams = {
  detailGridOptions: {
    columnDefs: [{ field: "price", headerName: "Price" }],
  },
  getDetailRowData: (params) => {
    params.successCallback(params.data.details);
  },
};

const AgTableClient = forwardRef(function (props, ref) {
  // Column definitions
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingView, setLoadingView] = useState(false);
  const [initialColumnState, setInitialColumnState] = useState([]);

  const gridRef = useRef();
  const processColumnDefs = (columnDefs) => {
    return columnDefs.map((obj) => ({
      ...obj, // Spread the original object
      ...(obj.formatType ? handleCellRenderer() : {}), // Add the new key-value pair
      ...(obj.editRow
        ? {
            editFunction: (params, status, colKey) =>
              handleCellEditing(params, status, colKey),
          }
        : {}),
    }));
  };

  useEffect(() => {
    if (gridApi && gridColumnApi) {
      props.autoResize && gridApi.sizeColumnsToFit();
    }
  }, [props.columnDefs, gridApi, gridColumnApi]);

  const onGridReady = useCallback(async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.autoSizeAllColumns();
  }, []);

  const onCellValueChanged = useCallback((event) => {}, []);

  const onRowValueChanged = useCallback((event) => {
    props.onRowSaved && props.onRowSaved(event.data);
  }, []);

  const handleCellEditing = (params, editingOn, colKey) => {
    if (!editingOn) {
      onBtStopEditing();
    } else {
      onBtStartEditing(params, colKey);
    }
  };
  const onBtStartEditing = useCallback((params, colKey) => {
    gridRef.current.api.setFocusedCell(params?.rowIndex, colKey);
    gridRef.current.api.startEditingCell({
      rowIndex: params?.rowIndex,
      colKey: colKey,
    });
  }, []);

  const onBtStopEditing = useCallback(() => {
    gridRef.current.api.stopEditing();
  }, []);

  const exportDataToExcel = useCallback(() => {
    gridRef.current.api.exportDataAsExcel({
      allColumns: true, // Include all columns in the export
      skipPinnedBottom: false, // Ensure pinned bottom rows (like grand total) are included
    }); // Access grid API from the ref
  }, [gridRef]);

  const exportDataToCSV = useCallback(() => {
    const params = {
      suppressQuotes: true, // Suppress quotes around cell values
      processHeaderCallback: (params) => {
        // Convert column headers to lowercase
        return params.column.getColDef().headerName.toLowerCase();
      },

      allColumns: true, // Include all columns in the export
      skipPinnedBottom: false, // Ensure pinned bottom rows (like grand total) are included

      processCellCallback: (params) => {
        // Convert cell values to lowercase if they are strings

        return params.value;
      },
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, [gridRef]);

  const getGridState = () => {
    const gridApi = gridRef.current?.api;
    const columnApi = gridRef.current.columnApi;
    console.log("getting grid state:", gridApi, gridColumnApi);
    return {
      columnState: columnApi.getColumnState(),
      sortState: columnApi
        .getAllGridColumns()
        .map((col) => ({
          colId: col.getColId(),
          sort: col.getSort(),
        }))
        .filter((col) => col.sort),
      filterState: gridApi.getFilterModel(),
      pivotMode: columnApi.isPivotMode(),
      rowGroupColumns: columnApi.getRowGroupColumns().map((col) => col.colId),
      pivotColumns: columnApi.getPivotColumns().map((col) => col.colId),
    };
  };

  const saveGridView = async (viewName, moduleId) => {
    console.log("saving veiw");
    const gridState = getGridState();
    console.log("grid state", gridState);
    const payload = {
      name: viewName,
      gridState: gridState,
      moduleId: moduleId,
    };
    const response = await apiPost(API_ENDPOINT_AG_TABLE_SAVE_VIEW, payload);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Awesome👍 , your view is created",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not save view, please try again",
      });
    }
  };

  const updateGridView = async (viewId, moduleId) => {
    const gridState = getGridState();
    const payload = {
      viewId: viewId,
      gridState: gridState,
      moduleId: moduleId,
    };
    const response = await apiPut(
      `${API_ENDPOINT_AG_TABLE_UPDATE_VIEW}${viewId}/update`,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Awesome👍 , your view is updated",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not save view, please try again",
      });
    }
  };

  const loadGridView = async (viewId, moduleId) => {
    const gridApi = gridRef.current.api;
    const columnApi = gridRef.current.columnApi;
    setLoadingView(true);

    if (viewId === "DEFAULT") {
      if (columnApi) {
        gridApi.resetColumnState();

        columnApi.applyColumnState({
          state: initialColumnState,
          applyOrder: false,
        });
        setLoadingView(false);

        columnApi.setRowGroupColumns([]); // Set row group columns
        columnApi.setPivotColumns([]); // Set pivot columns
        columnApi.setPivotMode(false); // Set pivot mode
      }

      // Apply sort and filter states
      if (gridApi) {
        gridApi.setFilterModel({}); // Apply filter model

        gridApi.refreshClientSideRowModel(); // Refresh the grid to apply the sorting
      }

      return;
    }
    const response = await apiGet(
      `${API_ENDPOINT_AG_TABLE_LOAD_VIEW}${moduleId}/${viewId}`
    );
    if (response.status && response.data) {
      const view = response.data;

      // Apply column state
      if (columnApi) {
        columnApi.applyColumnState({
          state: view.state.columnState,
          applyOrder: true,
        }); // Apply column state
        columnApi.setRowGroupColumns(view.state.rowGroupColumns); // Set row group columns
        columnApi.setPivotColumns(view.state.pivotColumns); // Set pivot columns
        columnApi.setPivotMode(view.state.pivotMode); // Set pivot mode
      }

      // Apply sort and filter states
      if (gridApi) {
        gridApi.setFilterModel(view.state.filterState); // Apply filter model

        // Apply sorting manually
        view.state.sortState.forEach((sort) => {
          gridApi.getColumnState().forEach((column) => {
            if (column.colId === sort.colId) {
              column.sort = sort.sort;
            }
          });
        });
        gridApi.refreshClientSideRowModel(); // Refresh the grid to apply the sorting
      }
    }
    setLoadingView(false);
  };

  React.useImperativeHandle(ref, () => ({
    exportDataToExcel,
    exportDataToCSV,
    exportJSONData,
    getAllColumns,
    onTableSearch,
    updateGridView,
    getGridState,
    saveGridView,
    loadGridView,
    setFilterModel: (filterModel) => {
      gridRef.current.api.setFilterModel(filterModel);
    },
    setSortModel: (sortModel) => {
      if (gridRef.current) {
        gridRef.current.api.setSortModel(sortModel);
      }
    },
    applyColumnState: (sortModel) => {
      if (gridRef.current) {
        gridRef.current.api.applyColumnState({
          state: sortModel.map((s) => ({ colId: s.colId, sort: s.sort })),
        });
      }
    },
    setGroupModel: (groupModel) => {
      gridRef.current.columnApi.setRowGroupColumns(
        groupModel.map((group) => group.colId || group.field)
      );
    },
  }));

  const exportJSONData = () => {
    if (gridApi) {
      const rowData = [];
      gridApi.forEachNode((node) => rowData.push(node.data));

      const transformedData = rowData.map((row) => {
        const obj = {};
        props.columnDefs.forEach((column) => {
          obj[column.field] = row[column.field];
        });
        return obj;
      });

      return transformedData;
    }
  };

  const handleColumnVisibilityChange = () => {
    const columnState = gridApi.getColumnState();
    const groupedColumn = columnState.find((column) => column.hide);
    props.onColGroup && props.onColGroup(groupedColumn);
    // setGroupedColumn(groupedColumn ? groupedColumn.colId : null);
  };

  const getAllColumns = () => {
    console.log("all columsn", gridRef.current);
    // const allColumns = gridRef.current.api.getAllColumns();
    // console.log(allColumns);
  };

  const statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left",
      },
      {
        statusPanel: "agTotalRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agFilteredRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agSelectedRowCountComponent",
        align: "right",
      },
      {
        statusPanel: "agAggregationComponent",
        align: "right",
      },
    ],
  };

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "pdfRenderer",
          labelDefault: "PDF View",
          labelKey: "pdfRenderer",
          iconKey: "pdf-file",
          width: 500,
          isOpen: true,
          toolPanel: props.pdfV2 ? PDFViewerToolV2 : PDFViewerTool,
          toolPanelParams: {
            dataKey: props.pdfDataKey,
            pdfData: props.pdfData,
          },
        },
      ],
    }),
    []
  );

  const onTableSearch = (event) => {
    gridRef.current && gridRef.current.api.setQuickFilter(event.target.value);
  };

  return (
    <div className="AgTable">
      {props.showExport || props.leftTopActions || props.rightTopActions ? (
        <div className="TableActionContainer">
          <div className="LeftContainer">{props.leftTopActions}</div>
          <div className="RightContainer">
            {props.rightTopActions}
            <div className="ActionButton">
              {props.showExport ? (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  shape="round"
                  size="small"
                  style={{
                    fontSize: 12,
                  }}
                  id="agTableExportButton"
                  onClick={exportDataToExcel}
                >
                  {props.exportLabel || "Export"}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <div className="ag-theme-alpine">
        <div style={{ height: "100%", position: "relative" }}>
          {loadingView ? (
            <div className="CustomLoading">
              <Loader />
            </div>
          ) : null}
          <AgGridReact
            rowData={props.rowData}
            columnDefs={processColumnDefs(props.columnDefs) || []}
            // defaultColDef={processColumnDefs(props.columnDefs) || []}
            pagination={!props.footer ? false : true}
            paginationPageSize={1000}
            rowSelection="multiple"
            suppressCellSelection={true}
            resizable={true}
            enablePivot={true}
            ref={gridRef}
            enableSorting={true}
            enableFilter={true}
            statusBar={props.showStatusBar && statusBar}
            groupMultiAutoColumn={true}
            rowGroupPanelShow={props.groupPanel ? "always" : "never"}
            pivotPanelShow="always"
            onGridReady={onGridReady}
            grandTotalRow={props.grandTotalRow}
            // pinnedBottomRowData={props.pinnedBottomRowData}
            pivotColumnGroupTotals="before"
            // pivotColumns={["Vendor Name"]}
            // autoGroupColumnDef={["Vendor Name"]}
            // onColumnGrouped={onColumnGrouped}
            //function related to cell/row edit change
            onCellValueChanged={onCellValueChanged}
            onRowValueChanged={onRowValueChanged}
            //function related to cell/row edit change
            editType={"fullRow"}
            onColumnVisible={handleColumnVisibilityChange}
            suppressClickEdit={true}
            autoSizeStrategy={
              props.autoResize
                ? {
                    type: "fitGridWidth",
                    defaultMinWidth: 100,
                  }
                : {}
            }
            defaultColDef={{
              resizable: props.autoResize,
            }}
            sideBar={props.hideToolbar ? {} : sideBar}
            autoGroupColumnDef={{
              headerName: "Group",
              cellRenderer: "agGroupCellRenderer",
              cellRendererParams: {
                innerRenderer: (params) => {
                  // Find the original column definition based on the field name
                  const originalColumn = props.columnDefs.find(
                    (colDef) => colDef.field === params.node.field
                  );
                  console.log("mainGroupRow:", params);
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyItems: "center",
                      }}
                    >
                      {handleAgCellDataFormat(
                        originalColumn?.formatType || "",
                        params.value,
                        originalColumn?.formatProps || "",
                        params.data || {}
                      )}
                      {`${
                        props.grandTotalRow && params.value === undefined
                          ? "Total"
                          : params.value === "NA"
                          ? "-"
                          : "-"
                      }`}
                    </div>
                  );
                },
              },
            }}
          />
        </div>
      </div>
      {contextHolder}
    </div>
  );
});
export default AgTableClient;
